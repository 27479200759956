body { background:#3d1e08; color:#434132; cursor:default; font-size:12px; font-family: Helvetica, sans-serif, Georgia, "Times New Roman", Times, serif; margin:0; padding:0; }
body.fb { background:#fff; color:#434132; cursor:default; font-size:12px; font-family:Georgia, "Times New Roman", Times, serif; margin:0; padding:0; text-align:center;}
	#wrapper { font-size:1.2em; margin:0 auto; text-align:left; width:960px;}
	#manager-wrapper { font-size:1.2em; margin:0 auto; text-align:left; width:710px; background-color: #DFD4B4}
	#fb-wrapper { font-size:1.0em; margin:0 auto; text-align:left; width:520px; }
*{
	min-height: 0;
	min-width: 0;
}
#root {
	min-width: 1020px;
}
/* ---------------------------------------------------------------------------------------------------------------------------------
	1) RESETS & UNIVERSAL CLASSES
/* --------------------------------------------------------------------------------------------------------------------------------- */

	body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, form, fieldset, input, textarea, p, blockquote, th, td { margin:0; padding:0;}
	fieldset, img, abbr, acronym { border:0 none;}
	table { border-collapse:separate; border-spacing:0;}
	:focus { outline:0;}
	address, caption, cite, code, dfn, th, var { font-style:normal; font-weight:normal;}
	caption, th { text-align:left;}
	h1, h2, h3, h4, h5, h6 { font-size:100%; font-weight:normal;}
	blockquote:before, blockquote:after, q:before, q:after { content:"";}

	.hidden { display:none;}
	label.hidden { display:inline; left:-9000px; position:absolute;}
	.nomargin { margin:0 !important;}
	.fleft { float:left;}
	.fright { float:right;}
	.clear { clear:both; height:0; line-height:0; visibility:hidden;}
	.clearfix:after, dl:after { content:"."; display:block; height:0; clear:both; visibility:hidden;}
	#ie6 .clearfix, #ie6 dl, #ie7 .clearfix, #ie7 dl { display:inline-block;}

	/* https://purecss.io/forms/? */
	.pure-form input[type=password],
	.pure-form input[type=email],
	.pure-form input[type=url],
	.pure-form input[type=date],
	.pure-form input[type=month],
	.pure-form input[type=time],
	.pure-form input[type=datetime],
	.pure-form input[type=datetime-local],
	.pure-form input[type=week],
	.pure-form input[type=tel],
	.pure-form input[type=color],
	.pure-form input[type=number],
	.pure-form input[type=search],
	.pure-form input[type=text],
	.pure-form select,
	.pure-form textarea {
    padding: .5em .6em;
    display: inline-block;
    border: 1px solid #ccc;
    box-shadow: inset 0 1px 3px #ddd;
    border-radius: 4px;
    vertical-align: middle;
    box-sizing: border-box;
	}
	.pure-form input[type=password]:focus,
	.pure-form input[type=email]:focus,
	.pure-form input[type=url]:focus,
	.pure-form input[type=date]:focus,
	.pure-form input[type=month]:focus,
	.pure-form input[type=time]:focus,
	.pure-form input[type=datetime]:focus,
	.pure-form input[type=datetime-local]:focus,
	.pure-form input[type=week]:focus,
	.pure-form input[type=tel]:focus,
	.pure-form input[type=color]:focus,
	.pure-form input[type=number]:focus,
	.pure-form input[type=search]:focus,
	.pure-form input[type=text]:focus,
	.pure-form select:focus,
	.pure-form textarea:focus {
		outline: 0;
    border-color: #129FEA;
	}

	.pure-form dl dd label, .pure-form label {
		float: none;
		text-align: right;
    display: inline-block;
	}

	.pure-form dl dd label {
		padding: 6px 12px 0;
		vertical-align: top;
	}

	.pure-form.list label {
		vertical-align: middle;
	}

	.pure-form.list input, .pure-form.list select {
		margin: 0px 20px 0px 4px;
	}

	.pure-form select {
		height: 30px
	}

    .product-RDFa { display: none; }

    img.spacing { margin: 10px}

    /* .rounded { -moz-border-radius:7px; -webkit-border-radius:7px; border-radius:7px; } */

    .thankyou { display: none }


/* ---------------------------------------------------------------------------------------------------------------------------------
	2) TYPOGRAPHY
/* --------------------------------------------------------------------------------------------------------------------------------- */

	a { color:#b75031; font-weight:normal; text-decoration:none;}
	a:hover { color:#8A1000; cursor:pointer; text-decoration:underline;}
	a:active { text-decoration:underline;}
    a.leftbanner a.leftbanner:active, a.leftbanner:hover{ text-decoration: none}
	h1, h2, h3, h4, h5, h6, p { margin:0 0 15px 0;}
	h1.smallmargin, h2.smallmargin, h3.smallmargin, p.smallmargin { margin:0 0 5px 0;}
	p { line-height:1.5em; margin-bottom: 1.5em}
	h1 { font-size:2em; color:#8a1000;}
	h2 { font-size:1.6em; color:#8a1000;}
	h3 { font-size:1.3em; color:#543a1e;}
	h4, h5, h6 { font-size:1.1em; font-weight:bold; margin-bottom:7px; color:#382510;}
	ol, ul { margin-left:20px;}
	li { margin-bottom:3px;}
	input, select, textarea, optgroup { font:1em Arial, Helvetica, sans-serif;}
	strong, .strong { font-weight:bold;}
	em, .em { font-style:italic;}
	acronym { font-weight:bold;}
	section { width: 100% }

  /* ---------------------------------------------------------------------------------------------------------------------------------
  	4) TEMPLATE STRUCTURE
  /* --------------------------------------------------------------------------------------------------------------------------------- */

  	/* Header */
  	/* #header { background:#a77832 url(http://www.londoncigcard.co.uk/images/textures.jpg) no-repeat 0 0; margin-bottom:10px; padding:0 10px 10px;}
  		#header .inner { background:#dfd1a8 url(http://www.londoncigcard.co.uk/images/textures.jpg) no-repeat -10px 0; padding:15px 10px 10px; position:relative;}
  		#header .companylogo { text-indent:-9000px; background:url(http://www.londoncigcard.co.uk/images/logo.jpg) no-repeat 0 0; width:728px; height:28px; font-size:2.4em; font-weight:bold; text-transform:uppercase;}
  		#header .companylogo a { display:block; width:728px; height:28px;}
  		#header .phone { position:absolute; right:10px; top:20px; color:#8c0d00; font-size:1.2em; background:#e5c174; padding:3px 10px; border:3px solid #cba457; border-width:3px 0;}

  		#nav { height:60px; width:435px;}
  		#nav dd { float:left; width:145px; margin-bottom:2px;}
  		#nav a { color:#36220e; text-transform:uppercase; border-bottom:1px solid #88704a; font-size:0.9em; padding:2px 5px 0;}
  		#nav a:hover, #nav a:focus { background:#A77832; border-color:#A77832; text-decoration:none; color:#fff;}
  		#nav a.selected { background:#543A1E; border-color:#543A1E; color:#fff;}


  	#midbanners { background:#a77832 url(http://www.londoncigcard.co.uk/images/textures.jpg) no-repeat 0 -150px; margin-bottom:10px; padding:10px 10px 10px 0; height:156px;}
  		#midbanners .box { background:#ede1bf; width:300px; border:3px solid #7d5b3b; height:150px; margin:0 0 0 10px;}
  		#midbanners .inner { border:2px solid #fff; height:145px; padding:0px; overflow: hidden}
  		#midbanners h2 { font-weight:bold;}
  		#midbanners h4 { text-transform:uppercase; font-weight:normal; font-size:0.9em;}
  		#midbanners p a { color:#8a1000; font-weight:bold; text-decoration:underline;}
  		#midbanners em { color:#382510; display:block; font-size:0.9em; padding-top:7px; font-family:Arial, Helvetica, sans-serif; line-height:1.2em;}
  		#midbanners em a { color:#382510; font-weight:normal;}

      #midbanners.thin { background:#a77832 url(http://www.londoncigcard.co.uk/images/thin_banner_bg.jpg) no-repeat; height:56px;}
          #midbanners.thin .box { width:459px; height:51px }
          #midbanners.thin .inner { height:47px; } */

		/* Header */
		#basket { width:370px; padding:15px 20px 0; }
		#basket dt { font-size:1.5em; color:#382510; display:inline; float:left; }
		#basket dd.items, #basket dd.total { padding:5px 2px; display:inline; float:left;}
		#basket dd.total strong { text-transform:uppercase;}
		#basket dd.checkout { float: right; margin:10px 0 0 0}
		#basket .viewbasket { display: block; width:211px; float: right; text-align: center; height:27px; font-weight: bold; background:#ae1000 url(http://www.londoncigcard.co.uk/images/basket_header.jpg) no-repeat; color:#fff;}
		#basket .account { margin: 8px 0 0 0; display: block }
		#basket .account a { font-weight: bold; border-bottom:2px solid #d3c6a1; text-decoration: none; float: left; margin-top: 17px;}
		#basket .account div { float: left; margin-top: 17px;}

		/* Footer */
		footer .sitemap { width:215px;}
		footer .security { width:440px; text-align:center;}
		footer dt { font-weight:bold; color:#292820; margin-bottom:10px;}
		footer dl a { color:#434132; font-size:0.9em; padding:2px 0; display:block;}
		footer dl a:hover { color:#292820;}
		footer .security p { font-size:0.9em;}
		footer .phone { color:#8c0d00; font-size:1.5em; background:#fdfbf7; padding:8px 0; border:1px solid #ccb888;}

  	/* Page Layout */
  	.contentWrapper { margin-bottom:12px; justify-content: center;}
  		.contentOuter { background:#DFD4B4; padding:12px; margin: 0 12px; width: 100%}
  		.content { background:#F5F1E6; padding-top: 12px; width: 100%;}
			.left { padding:0 12px;}
			.left .leftNav a, .left .infopages a { color:#d6ac2b; font-family:Arial, Helvetica, sans-serif; border-bottom:1px solid #766144; display:block; padding:4px;}
			.left .leftNav a:hover, .left .leftNav a:focus, .left .infopages a:hover, .left .infopages a:focus { background:#60482d; text-decoration:none;}
			.left .leftNav a.selected {color: #B7501B; font-weight: bold}
			.left .leftNav .navSecondary { padding-left: 8px; }
      .left .navBanner { background:#543a1e; color: #a69370; padding:10px; margin-bottom:10px; width: 190px;}
      .left .infopages a { text-decoration: none; border-bottom:none}
      .left .navBanner .heading { color: #d2c29c; font-size: 18px}

			.headerCart { background: url('../../public/images/cart_bg.png') no-repeat;}

	/* ---------------------------------------------------------------------------------------------------------------------------------
		5) INNER STYLES
	/* --------------------------------------------------------------------------------------------------------------------------------- */

		.box { margin-bottom:10px; background: #f0e7cc; border: 2px solid #cbb787;}
		.box.highlight { background: #e6d4a6;}
		.inner { border:2px solid #fff; padding:10px; position:relative; font-size: 13px}

    .contactform textarea.small { width: 200px; height: 80px}
    .contactform textarea.medium { width: 300px; height: 150px}
    .contactform textarea.large { width: 350px; height: 200px}
		.contactform dl { }
		.contactform dl dd { margin: 5px 0}
		.contactform dl dd label { display: block; float: left }
		.contactform dl.small dd label { width:80px; }
		.contactform dl.medium dd label { width:150px; }
		.contactform dl.large dd label { width:220px; }

		ul#breadcrumb { list-style: none; margin: 0 0 5px -7px; padding: 0; overflow: hidden }
    ul#breadcrumb li { display: block; float: left; padding: 0 4px; }
    ul#breadcrumb li.seperator { padding: 0 0px; }
    ul#breadcrumb li.last a { font-weight: bold; color: #543A1E }

		table { border-collapse:separate; border-spacing:0; width:100%; margin: 0 0 10px 0}
    table { border:2px solid #cbb787; width: 100%; background-color: #FABC41 }
    table td { padding:2px 5px; border-bottom: 1px solid #cbb787 }

		table#catlist thead { }
    table#catlist thead tr { background-color: #990000; color: #E1E0C3 }
    table#catlist tbody tr:hover td.highlight { background-color: #FABC41; }
    table#catlist tbody td.highlight { background-color: #F5D89D }
    /*table#catlist tbody td a{ font-weight: bold; color: #656354}*/
    table#catlist tbody td .list{ font-weight: bold; }
    table#catlist tbody td .list.indented{ padding-left:8px }
    table#catlist tbody td .updated{ font-size:0.9em; color: #767465 }

		table#prodlist { background-color: #F5D89D }
    table#prodlist thead { }
    table#prodlist thead tr { background-color: #990000; color: #E1E0C3 }
    table#prodlist tbody tr {  }
    table#prodlist tbody td.highlight { background-color: #FABC41 }
    /*table#prodlist tbody td a{ font-weight: bold; color: #656354}*/
    table#prodlist tbody td .list{ font-weight: bold; }
    table#prodlist tbody td .updated{ font-size:0.9em; color: #767465 }

		.lightBack { background:#fdfdf9; border:1px solid #cab686; padding:12px;}

		.person { height: 120px;}
		.product { font-family:Arial, Helvetica, sans-serif; background:#fdfdf9; border:1px solid #cab686; float:left; width:318px; margin-bottom:10px; padding:10px; position:relative;}
		.headerNav a {color: #be9926}

		.product .imgholder { width:170px; height:170px; text-align:center; margin-right:10px;}

		.product.pos1 { margin-right:10px;}
		.product h4 { margin:0px 0 0; font-size:1em}
		.product h4 a { color:#543a1e; text-decoration:none; /*border-bottom:1px solid #d2c7b1;*/ font-weight:bold;}
		.product h4 a:hover { color:#8A1000; border-color:#8A1000;}
		.product p.desc { margin-top: 3px; font-size: .9em; margin-bottom: 1px; }
		.product p.price { margin: 3px 0 }
		.product .brand, .product .pre, .product .wasprice { display:block;}
		.nowprice { font-weight:bold; padding:0 2px; color:#543A1E;}
		/* .product form { position:absolute; top:145px; right:13px;}*/
		/* .product .buy { background:#ccb888 url(http://www.londoncigcard.co.uk/images/add_to_basket.jpg) no-repeat; border: none; height:27px; width: 130px; display: block} */

		/* .product .details .more_info { background:#ccb888 url(http://www.londoncigcard.co.uk/images/more_info.jpg) no-repeat; border: none; height:27px; width: 130px; display: block} */
		.product .details .desc .list { display:block; margin: 7px 0}
		.product .details .desc .list .condition { font-weight: bold; color: #A54936 }
		.product .details .desc .list.smallerspace { margin: 2px 0}
		.product .details .desc .listtitle { font-weight:bold }
		.product .details .desc .abbr { display:block }
		.product .details .price .pre { font-weight:bold; padding-bottom: 3px}

    .productPage .nowprice { background:#a54936; color:#fff; padding:0 2px; color:#fff;}
    .productPage .wasprice .itemPrice { text-decoration:line-through;}

		.aws-btn {
			white-space: nowrap;
			--button-default-height: 28px;
			--button-default-font-size: 14px;
			--button-default-border-radius: 5px;
			--button-horizontal-padding: 12px;
			--button-raise-level: 3px;
			--button-hover-pressure: 2;
			--transform-speed: 0.185s;
			--button-primary-color: #D2C29C; /* background */
			--button-primary-color-dark: #766144; /* shadow */
			--button-primary-color-light: #434132; /* text */
			--button-primary-color-hover: #D2C29C; /* hover */
			--button-primary-color-active: #D2C29C; /* depressed */
			/*--button-primary-border: none;
			--button-secondary-color: #fffc6c;
			--button-secondary-color-dark: #b9b500;
			--button-secondary-color-light: #6c6a00;
			--button-secondary-color-hover: #fffb3e;
			--button-secondary-border: none;
			--button-anchor-color: #f3c8ad;
			--button-anchor-color-dark: #734922;
			--button-anchor-color-light: #4c3016;
			--button-anchor-color-hover: #f1bfa0;
			--button-anchor-border: 1px solid #8c633c; */
		}

/* ---------------------------------------------------------------------------------------------------------------------------------
	6) Page Forms
/* --------------------------------------------------------------------------------------------------------------------------------- */

    form.standard { margin-bottom:10px}
    form.standard label, .standardform label { float: left; display: block; width: 100px }
    form.standard dt, form.standard dd, .standardform dt, .standardform dd { margin-bottom: 4px}

		#add_address dd { margin-bottom:8px}
    #add_address label { text-align:right; margin-right:10px}
    #add_address label.checkbox { text-align:left; width:260px; }
    #add_address input.checkbox { display:block; float:left; height:30px; margin-right:10px }

		.pick_address_box label{ display:block; position: relative; padding-left: 17px}
    .pick_address { display:block; float:left; margin-right:8px; position: absolute; top:40%; left:0px;}

    #update_phone label { text-align:right; margin-right:10px; width:120px; }
