.productListItem {
  background: #fdfdf9;
  border:1px solid #cab686;
  margin-bottom:8px;
  padding:8px;
  min-height: 200px
}

.productListItem.noBorder {
  border:0;
}

.homeListItem {
  background: #fdfdf9;
  border:1px solid #cab686;
  margin-bottom:8px;
  padding:8px;
}

.productListItem img {
  margin-left: auto;
	margin-right: auto;
	display: block;
}
