.react-paginate { margin: 0 10px; position: absolute; right: 0;}
.react-paginate ul { padding: 0; margin: 0; margin-bottom: 12px }

.react-paginate li {
  display: inline-block;
  text-decoration: none;
  border: 1px solid #eee;
  border-color: #CBB787;
  background: #E6D4A6;
  margin: 0 2px 0 0;
  padding: 2px 4px;
  border-radius: 4px
}

.react-paginate li a {
  color: #b75031;
  font-weight: normal;
  text-decoration: none;
  cursor: pointer;
}

.react-paginate li.active {
  background: #60482D;
}

.react-paginate li.active a {
  color: #fff;
}

.react-paginate .break a {
  cursor: default;
}
